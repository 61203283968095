<template>
  <splide :options="options" :slides="items">
    <template v-slot:controls>
      <div class="splide__arrows">
        <button class="splide__arrow splide__arrow--prev">
          <font-awesome-icon :icon="['fas', 'angle-right']" />
        </button>
        <button class="splide__arrow splide__arrow--next">
          <font-awesome-icon :icon="['fas', 'angle-right']" />
        </button>
      </div>
    </template>
    <splide-slide v-for="item in items" :key="item.id">
      <div class="service-item service-card light-green-card">
<!--        <div class="service-item-image">-->
<!--          <img :src="item.image"/>-->
<!--        </div>-->
        <div class="service-item-content">
          <div class="service-item-title">
            {{ item.first_name }}
          </div>
          <p class="service-item-description">
            {{ item.department }}
          </p>
          <button class="service-item-book-btn generic-button" @click="$router.push({ path: `/apps/preventiveHealth/healthpackage/${item.name}`, query: {pn: item.first_name}})">
            <span>{{$t('landingPage.bookNow')}}</span>
            <i class="arrow arrow-right"></i>
          </button>
        </div>
      </div>
    </splide-slide>
  </splide>
</template>

<script>
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import '@splidejs/splide/dist/css/themes/splide-sea-green.min.css';
import '@splidejs/splide/dist/css/themes/splide-skyblue.min.css';

export default {
  name: "card-list-carousel",
  components: {
    Splide,
    SplideSlide,
  },
  props: {
    items: [],
    options: []
  }
};
</script>

<style scoped lang="scss">
@import "@/assets/scss/variables.scss";
.splide {
  padding: 0 80px !important;
  .splide__arrows {
    svg {
      fill: $lightGreen;
    }
  }
}
.service-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  min-height: 220px;
  padding: 20px 0;
  &.green-card {
    //background-color: $darkGreen;
    background-color: var(--cardColorActive);
    .service-item-content {
      color: $white;
    }
    .service-item-book-btn {
      background-color: $white;
    }
  }
  &.light-green-card {
    //background-color: $lightGreen;
    background-color: var(--cardColor);
    .service-item-content {
      color: $white;
    }
    .service-item-book-btn {
      background-color: $white;
    }
  }
  &.light-yellow-card {
    background-color: #FFE9B3;
    .service-item-content {
      color: $green;
      .service-item-title {
        color: $green;
      }
      .service-item-description {
        color: $green;
      }
    }
    .service-item-book-btn {
      background-color: $darkGreen;
      color: $white !important;
    }
  }
  &.yellow-card {
    background-color: #FFCC73;
    .service-item-content {
      color: $green;
      .service-item-title {
        color: $green;
      }
      .service-item-description {
        color: $green;
      }
    }
    .service-item-book-btn {
      background-color: $darkGreen;
      color: $white !important;
    }
  }
  .service-item-image {
    width: 50%;
    max-width: 160px;
    padding-left: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
  .service-item-content {
    text-align: center;
    padding: 15px;
    margin: 0 auto;
    display: flex;
    flex-flow: column nowrap;
    .service-item-title {
      text-transform: uppercase;
      color: #FFFFFF;
      font-weight: 700;
      padding-bottom: 10px;
      font-size: 18px;
    }
    .service-item-description {
      font-size: 14px;
      color: #FFFFFF;
      padding-bottom: 25px;
    }
    .service-item-book-btn {
      //color: $lightGreen;
      color: var(--labelColor);
      padding: 8px 18px;
      text-transform: capitalize;
      font-weight: 400;
      margin-top: auto;
      align-self: center;
      span {
        position: relative;
        &:after {
          content: '';
          width: 98%;
          position: absolute;
          left: 0;
          bottom: -1px;
          border: 1px solid;
        }
      }
      .arrow {
        display: inline-block;
        border: solid;
        border-width: 0 2px 2px 0;
        padding: 3px;
        margin-left: 3px;
      }
      .arrow-right {
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
      }
    }
  }
}
.service-card {
  border-radius: 28px;
}

@media (max-width: 1000px) {
  .splide {
    padding: 0 60px !important;
  }
}
@media (max-width: 800px) {
  .service-item {
    flex-direction: column;
    align-items: center;
    min-height: 240px;
  }
}
@media (max-width: 600px) {
    .splide {
      padding: 0 50px !important;
    }
    .service-item {
      .service-item-image {
        width: 100%;
      }
      .service-item-content {
        .service-item-title {
          font-size: 14px;
        }
        .service-item-book-btn {
          font-size: 14px;
        }
      }
    }
}
.fa-angle-left, .fa-angle-right {
  color: var(--lGreen);
  font-size: 30px;
}
</style>
